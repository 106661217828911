import { ViewportScroller } from '@angular/common';
import { Injectable, OnDestroy, OnInit, inject } from '@angular/core';
import { Event, NavigationSkipped, Router, Scroll } from '@angular/router';
import { Subscription, filter, pairwise } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class NavigationScrollService implements OnInit, OnDestroy {
    private router = inject(Router);
    private viewportScroller = inject(ViewportScroller);

    public navScrollPosition: number = 0;
    private navScrollSubs$!: Subscription;

    ngOnInit() {
        this.navScrollSubs$ = this.router.events
            .pipe(
                filter((e: Event): e is Scroll => e instanceof Scroll),
                pairwise(),
            )
            .subscribe((e: Scroll[]) => {
                const previous = e[0];
                const current = e[1];
                if (this.navScrollPosition > 0) {
                    // Manual scroll
                    this.viewportScroller.scrollToPosition([
                        0,
                        this.navScrollPosition,
                    ]);
                    this.navScrollPosition = 0;
                } else if (current.routerEvent instanceof NavigationSkipped) {
                    // Skipped navigation
                } else if (current.position) {
                    // Backward navigation
                    this.viewportScroller.scrollToPosition(current.position);
                } else if (current.anchor) {
                    // Anchor navigation
                    this.viewportScroller.scrollToAnchor(current.anchor);
                } else {
                    // Check if routes match, or if it is only a query param change
                    if (
                        this.getBaseRoute(previous.routerEvent.url) !==
                        this.getBaseRoute(current.routerEvent.url)
                    ) {
                        // Routes don't match, this is actual forward navigation
                        // Default behavior: scroll to top
                        this.viewportScroller.scrollToPosition([0, 0]);
                    }
                }
            });
    }

    ngOnDestroy(): void {
        if (this.navScrollSubs$) {
            this.navScrollSubs$.unsubscribe();
        }
    }

    private getBaseRoute(url: string): string {
        // return url without query params
        return url.split('?')[0];
    }
}
