import { Injectable, inject } from '@angular/core';
import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
} from '@angular/common/http';
import { Config } from '@core/service/config';
import { Log } from '@core/service/logger';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppStorageService } from '@shared/service/storage/storage.service';

export interface Response {
    status: number;
    json?: any;
    message?: string;
}

@Injectable({ providedIn: 'root' })
export class ApiService {
    private _http = inject(HttpClient);
    private _config = inject(Config);
    private _log = inject(Log);
    private _storageService = inject(AppStorageService);

    protected data: {} = {};
    protected json: string = '';

    /**
     *
     *
     * @param {string} _path
     * @param {Object} _data
     * @param {boolean} _file
     * @return {Promise<Response>}
     */
    public get(
        _path: string,
        _data: object = {},
        _file: boolean = false
    ): Observable<Response> {
        this.params(_data);
        const json: string = this.json;

        const urlObj = new URL(this.createUrl(_path));
        if (json !== '') {
            urlObj.searchParams.set('data', json);
        }

        const url: string = urlObj.toString();
        this._log.debug('GET', url);

        // Если в ответе будет файл
        const headers: any = {
            headers: this.header(),
        };
        if (_file) {
            headers.responseType = 'blob';
        }
        return this._http.get(url, headers).pipe(
            map((body) => {
                if (_file) {
                    return <any>body;
                }
                return this.reply(<any>body);
            }),
            catchError(this.replyError)
        );
    }

    /**
     * Обновления
     *
     * @param {string} _path
     * @param {Object} _data
     * @return {Promise<Response>}
     */
    public put(_path: string, _data: { [key: string]: any } = {}) {
        const listFile: { [key: string]: File | Blob } = {};
        const listData: { [key: string]: any } = {};
        const formData: FormData = new FormData();
        let isFile = false;
        for (const key of Object.keys(_data)) {
            const val = _data[key];
            if (val instanceof File) {
                listData[key] = val;
            } else if (val instanceof Array) {
                for (const vv of val) {
                    if (vv instanceof File) {
                        formData.append(key, vv, vv.name);
                        isFile = true;
                    } else {
                        listData[key] = val;
                    }
                }
            } else {
                listData[key] = val;
            }
        }

        this.params(listData);
        const url: string = this.createUrl(_path);
        let header = this.header();

        let body: any;
        if (Object.keys(listFile).length > 0 || isFile) {
            formData.append('data', this.json);

            for (const key of Object.keys(listFile)) {
                const val = listFile[key];
                if (val instanceof File) {
                    formData.append(key, val, val.name);
                } else if (val instanceof Blob) {
                    formData.append(key, val);
                }
            }

            body = formData;
            header = header.delete('Content-Type', '');
        } else {
            body = this.json;
        }

        this._log.debug('PUT', url);

        return this._http.put(url, body, { headers: header }).pipe(
            map((res) => this.reply(<any>res)),
            catchError(this.replyError)
        );
    }

    /**
     * Создания
     *
     * @param {string} _path
     * @param {Object} _data
     * @return {Promise<Response>}
     */
    public post(_path: string, _data: { [key: string]: any } = {}) {
        const listFile: { [key: string]: File | Blob } = {};
        const listData: { [key: string]: any } = {};
        const formData: FormData = new FormData();
        let isFile = false;
        for (const key of Object.keys(_data)) {
            const val = _data[key];
            if (val instanceof File || val instanceof Blob) {
                listData[key] = val;
            } else if (val instanceof Array && val.length > 0) {
                for (const vv of val) {
                    if (vv instanceof File) {
                        formData.append(key, vv, vv.name);
                        isFile = true;
                    } else {
                        listData[key] = val;
                    }
                }
            } else if (typeof val === 'object' && val !== null) {
                for (const kk of Object.keys(val)) {
                    if (val[kk] instanceof File) {
                        formData.append(key + '.' + kk, val[kk], val[kk].name);
                        isFile = true;
                    } else {
                        listData[key] = val;
                    }
                }
            } else {
                listData[key] = val;
            }
        }

        this.params(listData);
        const url: string = this.createUrl(_path);
        let header = this.header();

        let body: any;
        if (Object.keys(listFile).length > 0 || isFile) {
            formData.append('data', this.json);

            for (const key of Object.keys(listFile)) {
                const val = listFile[key];
                if (val instanceof File) {
                    formData.append(key, val, val.name);
                } else if (val instanceof Blob) {
                    formData.append(key, val);
                }
            }

            body = formData;
            header = header.delete('Content-Type', '');
        } else {
            body = this.json;
        }

        return this._http.post(url, body, { headers: header }).pipe(
            map((res) => this.reply(<any>res)),
            catchError(this.replyError)
        );
    }

    /**
     * Удаление
     *
     * @param {string} _path
     * @param {Object} _data
     * @return {Promise<Response>}
     */
    public delete(_path: string, _data: { [key: string]: any } = {}) {
        const listData: { [key: string]: any } = {};
        for (const key of Object.keys(_data)) {
            const val = _data[key];
            listData[key] = val;
        }

        this.params(listData);
        const url: string = this.createUrl(_path);

        return this._http.delete(url, { headers: this.header() }).pipe(
            map((res) => this.reply(<any>res)),
            catchError(this.replyError)
        );
    }

    /**
     * Возращает отформатированный ответ из запроса
     *
     * @param {Response} res
     * @return {ApiResponse}
     */
    private reply(res: Response) {
        const reply = {
            json: res,
        };
        return <Response>reply;
    }

    /**
     * Возращает отформатированный ответ ошибки
     *
     * @param {HttpErrorResponse} res
     *
     * @return Observable<Response>
     */
    private replyError(res: HttpErrorResponse): Observable<Response> {
        const reply = {
            message: res.error.message,
            status: res.status,
        };
        return throwError(() => {
            return reply;
        });
    }

    /**
     * Создания url
     *
     * @param path
     * @returns {string}
     */
    protected createUrl(path: string): string {
        let urlPath = '';
        const api = this._config.get('api');
        const port = api['port'];
        const protocol = api['protocol'];
        const host = api['url'];
        const queryParams = api['queryParams'] || null;

        urlPath += protocol + '://';
        urlPath += host;
        if (port) {
            urlPath += ':' + port;
        }
        urlPath += '/' + path + '/';

        const url = new URL(urlPath);

        if (Array.isArray(queryParams)) {
            queryParams.forEach((item) => {
                url.searchParams.set(item.key, item.value);
            });
        }

        return url.toString();
    }

    /**
     * Возращает header
     *
     * @param params
     * @returns {Headers}
     */
    protected header(params: {} = {}): HttpHeaders {
        let headers: HttpHeaders = new HttpHeaders({});
        const configHeader = this._config.get('api')['header'];

        /**
         * Конфиг
         */
        for (const prop in configHeader) {
            if (configHeader.hasOwnProperty(prop)) {
                headers = headers.append(
                    prop.toString(),
                    configHeader[prop].toString()
                );
            }
        }

        /**
         * Входящие параметры
         */
        if (params) {
            for (const prop in params) {
                if (configHeader.hasOwnProperty(prop)) {
                    headers = headers.append(
                        prop.toString(),
                        configHeader[prop].toString()
                    );
                }
            }
        }

        /**
         * Токен
         */
        const authToken = this._storageService.getItem('auth_token')
            ? this._storageService.getItem('auth_token')
            : '';
        if (authToken && authToken.length > 0) {
            headers = headers.append('auth_token', authToken);
        }
        return headers;
    }

    /**
     * Подготовка данных
     *
     * @param _data обьект с данными
     *
     * @returns {string}
     */
    protected params(_data: object): void {
        this.data = _data;
        this.json = <string>JSON.stringify(this.data);
    }

    // установка постранички по умолчанию
    private setPagination(obj?: { [x: string]: any }): { [x: string]: any } {
        const config = this._config.get('default');

        if (obj === undefined || obj === null) {
            obj = {
                page: config.startPage,
                count: config.itemsPerPage,
            };
        } else {
            if (!obj['page'] || !parseInt(obj['page'])) {
                obj['page'] = config.startPage;
            } else {
                obj['page'] = parseInt(obj['page']);
            }
            if (!obj['count'] || !parseInt(obj['count'])) {
                obj['count'] = config.itemsPerPage;
            } else {
                obj['count'] = parseInt(obj['count']);
            }
        }

        return obj;
    }

    // отбрасываем все параметры, кроме указанных, чтобы в API не слать лишние поля
    private filterQueryParams(
        inputParams: { [x: string]: any },
        acceptableValues: string[]
    ) {
        let outputParams: { [x: string]: any } = {};
        for (let param in inputParams) {
            if (acceptableValues.indexOf(param) >= 0) {
                outputParams[param] = inputParams[param];
            }
        }
        return outputParams;
    }

    // обработка параметров запрос
    // добавить постраничку если надо
    // отсечь пустые или невалидные значения и т.д.
    public prepareParams(
        obj?: { [x: string]: any },
        needPagination?: boolean,
        acceptableValues?: string[]
    ): { [x: string]: any } {
        let tmp: { [x: string]: any } = {};

        if (obj !== undefined && obj !== null) {
            if (acceptableValues) {
                if (needPagination) {
                    acceptableValues.push('page', 'count');
                }
                obj = this.filterQueryParams(obj, acceptableValues);
            }
            for (let paramName in obj) {
                if (
                    obj[paramName] &&
                    obj[paramName] !== '' &&
                    obj[paramName] !== null
                ) {
                    if (obj[paramName] === 'false') {
                        tmp[paramName] = false;
                    } else if (obj[paramName] === 'true') {
                        tmp[paramName] = true;
                    } else if (
                        obj[paramName] === '-1' ||
                        obj[paramName] === -1
                    ) {
                        // игнорируем -1, т.к. оно значит "все"
                        // по сути то же самое что не указывать этот параметр
                        // для его реализации нужны костыли в апи
                    } else {
                        tmp[paramName] = obj[paramName];
                    }
                }
            }
        }

        if (needPagination) {
            tmp = this.setPagination(tmp);
        }

        return tmp;
    }

    downloadFile(
        res: any,
        name: string = 'report',
        isZip: boolean = false
    ): void {
        const blob = new Blob([res], { type: res.type });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const now = new Date();
        const fileName =
            name +
            '_' +
            now.getFullYear() +
            '-' +
            (now.getMonth() + 1) +
            '-' +
            now.getDate() +
            '_' +
            now.getHours() +
            '-' +
            now.getMinutes() +
            '-' +
            now.getSeconds();
        if (isZip) {
            link.download = fileName + '.zip';
        } else {
            link.download = fileName + '.xlsx';
        }
        link.click();
    }
}
